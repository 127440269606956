<template>
	<PopupTwoCol name="bpn"
	text="<h3>Bâtiment de production nord</h3><br/><p>Ce bâtiment accueille trois des cinq salles de contrôle du site. Tous les flux de pétrole sont contrôlés durant l’ensemble du processus du raffinage. Les unités concernées sont la distillation, le réformeur regénératif, le viscoréducteur, le FCC (Fluid Catalytic Cracking), l’alkylation, le mouvement des produits, ainsi que d’autres unités. Chaque tuyau, chaque vanne, chaque quantité de produit, chaque opération est ainsi contrôlé et régulé. Afin de surveiller la raffinerie 24h/24h et 365j/365j, les salariés travaillent en équipe de quart. C’est-à-dire que leur rythme de travail alterne entre le matin, l’après-midi et la nuit.</p><br/><div class='popup__statistics'><p class='dark__red'>Chiffre clé</p><p class='text__big'>300 000</p><p>c'est le nombre de capteurs qui permettent de piloter les unités de fabrication.</p></div>">
	<template v-slot:thumbnail__1>
		<picture>
			<img class="lazy"
			sizes="(max-width: 1366px) 300px,
			(max-width: 1920px) 410px,
			970px"
			data-srcset="
			img/popup/bpn_hhuovz_c_scale_w_350.jpg 350w,
			img/popup/bpn_hhuovz_c_scale_w_717.jpg 717w,
			img/popup/bpn_hhuovz_c_scale_w_970.jpg 970w"
			data-src="img/popup/bpn_hhuovz_c_scale_w_970.jpg"
			src="static/logo-total.jpg"
			alt="">
		</picture>
		<div class="legend">
			<p>Aujourd'hui</p>
		</div>
	</template>
	<template v-slot:imageGroup>
		<picture>
			<img class="lazy"
			sizes="(max-width: 512px) 100vw, 512px"
			data-srcset="
			img/popup/BPN_80_gzyfzl_c_scale_w_160.jpg 160w,
			img/popup/BPN_80_gzyfzl_c_scale_w_364.jpg 364w,
			img/popup/BPN_80_gzyfzl_c_scale_w_512.jpg 512w"
			data-src="img/popup/BPN_80_gzyfzl_c_scale_w_512.jpg"
			src="static/logo-total.jpg"
			alt="">
		</picture>
		<picture>
			<img class="lazy"
			sizes="(max-width: 512px) 100vw, 512px"
			data-srcset="
			img/popup/BPN_80_2_wbfggv_c_scale_w_160.jpg 160w,
			img/popup/BPN_80_2_wbfggv_c_scale_w_364.jpg 364w,
			img/popup/BPN_80_2_wbfggv_c_scale_w_512.jpg 512w"
			data-src="img/popup/BPN_80_2_wbfggv_c_scale_w_512.jpg"
			src="static/logo-total.jpg"
			alt="">
		</picture>
		<div class="legend">
			<p>Dans les années 1980</p>
		</div>
	</template>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>

<style lang="scss">
	.bpn {
		.thumbnail__1 {
			display: grid;
		}
	}
</style>
